.divider {
    border-bottom: 1px solid #FFF;
    background-color: #DADADA;
    height: 1px;
    margin: 0.5em 0 1.5em;
}

.divider span {
    display: block;
    width: 50px;
    height: 1px;
    background-color: var(--main-decorator-color);
}