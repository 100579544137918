.itemGroup {
    flex: 0 1 auto;
    position: relative;
    max-width: 100%;
    transition: .3s cubic-bezier(.25,.8,.5,1);
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.5, 1);
    transition-delay: 0s;
    border-radius: 0;
}
.btn {
    align-items: center;
    border-radius: inherit;
    display: inline-flex;
    justify-content: center;
    outline: 0;
    position: relative;
    text-decoration: none;
    flex: 0 0 auto;
    font-size: .875rem;
    color: rgba(0,0,0,.87);
    will-change: box-shadow;
    transition-property: box-shadow, transform, opacity;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
}

.btnToggle {
    border-radius: 4px;
    display: inline-flex;
    max-width: 100%;
}

.btnToggle > .btn.btn {
    border-radius: 0;
    border-style: solid;
    border-width: thin;
    box-shadow: none;
    opacity: .8;
    padding: 0 12px;
}
.btnElevation {
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}



.btnToggle> .btn:first-child {
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
}

.btnToggle > .btn.btn:last-child {
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
}
.btnToggle > .btn.btn:active {
    color: inherit;
    opacity: 1
}
.btnToggle > .btn.btn:after {
    display: none;
}

.btnToggle > .btn.btn {
    height: 48px;
    min-height: 0;
    min-width: 48px;
}

.btnToggleGroup {
    border-radius: 0;
}

.btnToggleGroup > .btn {
    background-color: transparent !important;
    border-color: transparent;
    margin: auto;
    min-width: auto;
}

.btn > .btnContent {
    align-items: center;
    color: inherit;
    display: flex;
    flex: 1 0 auto;
    justify-content: inherit;
    line-height: normal;
    position: relative;
}

.btn:before {
     border-radius: inherit;
     bottom: 0;
     color: inherit;
     content: "";
     left: 0;
     opacity: 0;
     pointer-events: none;
     position: absolute;
     right: 0;
     top: 0;
     transition: opacity .2s cubic-bezier(.4,0,.6,1);
     background-color: currentColor;
 }



.btnActive:before {
    border-radius: inherit;
    bottom: 0;
    color: inherit;
    content: "";
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity .2s cubic-bezier(.4,0,.6,1);
    background-color: currentColor;
}
.btn.btnActive:before {
    opacity: .18;
}
.btnActive:before, .btnActive:hover:before, .btnActive:focus:before {
    opacity: .12;
}


button.btnActive {
    color: currentColor;
    opacity: 1;
}

.btn[type=button] {
    cursor: pointer;
}