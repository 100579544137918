span.isApproved, span.isRejected {
    display: flex;
    border-radius: 50%;
    padding: 1px;
    fill: none;
    margin-left: 0.5rem;
    max-width: 34px;
    max-height: 34px;
    stroke: currentcolor;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;

}
span.isApproved svg, span.isRejected svg {
    border-radius: 50%;
    position: relative;
    width: 24px;
    height: 24px;
    color: white;
    border: 1px solid white;
    padding: 0.25rem;
}
span.isApproved {
    background-color: var(--main-decorator-color);
}
h4.isApproved {
    color: var(--main-decorator-color);
}
span.isRejected {
    background-color: var(--tomato);
}
h4.isRejected {
    color: var(--tomato);
}
.tabInfoContainer > h1 {
    margin-right: auto;
    margin-left: auto;
    max-width: 1300px;
}
.vigieInfoContainer {
    display: flex;
    max-width: 1300px;
    margin: auto;
    flex-direction: column;
}

.actionBtnContainer {
    display: flex;
}

.actionBtnContainer > div.rejectionHolder {
    display: flex;
    position: relative;
}

.actionBtnContainer button {
    margin: 0 0.5em;
}

.mobileOnly {
    
}
.errorContainer {
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    border: solid 2px var(--tomato);
    border-radius: 5px;
    color: var(--tomato);
}
.errorContainer span {
    align-items: center;
    display: flex;
    max-width: 50ch;
}

@media (max-width: 1182px) {
    .mobileOnly {
        flex-direction: column;
    }
    .mobileOnly h4 > div {
        margin: 0.5rem;
    }
}

@media (max-width: 616px) {
    .tabInfoContainer > div {
        align-items: flex-start;
        flex-direction: column;
    }

    .tabInfoContainer div div.actionBtnContainer {
        margin-top: 1rem;
    }
    .tabInfoContainer div div.actionBtnContainer button {
        margin-left: 0;
    }
}