.checkbox {
    background-color: var(--pale-grey);
    border: 2px solid var(--pale-grey);
    cursor: pointer;
    font-family: 'Montserrat';
    font-size: 16px;
    color: var(--black);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 0 20px;
    border-radius: 4px;
    text-align: left;
    transition: all 0.3s;
}

.checkbox:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

.checkbox:hover {
    border: 2px solid var(--main-decorator-color);
}

.checkbox span {
    min-width: 30px;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border: solid 1px var(--black40);
    border-radius: 50%;
    transition: all 0.3s;
    color: var(--main-decorator-color);
}

.checkbox:hover span {
    border: 1px solid var(--main-decorator-color);
}

.checkbox span svg {
    transition: all 0.4s;
    transform: scale(0) rotate(-180deg);
}

.checkbox.selected {
    background-color: var(--main-decorator-color);
    border: 2px solid var(--main-decorator-color);
    color: white;
}

.checkbox.selected span {
    border: solid 1px white;
    background-color: white;
}

.checkbox.selected span svg {
    transform: scale(1) rotate(0);;
}

.checkbox.naked {
    border: none;
    background-color: transparent;
    height: auto;
    padding: 0;
    color: var(--black) !important;
    font-size: 14px;
}

.checkbox.naked span {
    color: white;
    margin: 0;
}

.checkbox.naked .loader {
    width: 30px;
    height: 30px;
    padding: 2px;
    box-sizing: border-box;
}

.checkbox.selected.naked span {
    margin: 0;
    border: solid 1px var(--main-decorator-color);
    background-color: var(--main-decorator-color);
}
