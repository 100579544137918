.loaderContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    overflow-x: hidden;
    height: 100%;
}
.loaderContainer .loader {
    display: inline-flex;
    width: 100px;
    height: 100px;
    border: 3px solid rgba(255,255,255,.3);
    border-radius: 50%;
    border-top-color: #00874e;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

.loaderContainer .loader {
    position: relative;
    left: calc(50% - 50px);
    top: 50%;
}

.loaderContainer p {
    position: relative;
    top: 50%;
    left: calc(50% - 9ch);
    padding-top: 1.2rem;
}

@keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}