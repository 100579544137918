.button { 
    background-color: var(--main-decorator-color);
    border: 1px solid var(--main-decorator-color);
    cursor: pointer;
    font-family: 'Montserrat';
    font-size: 0.778rem;
    font-weight: 700;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 3.5em;
    padding: 0 1.05rem;
    border-radius: 0.222rem;
    transition: all 0.3s;
}

.button.secondary { 
    background-color: white;
    border: 1px solid var(--black);
    color: var(--black80);
}

.button:hover {
    transform: scale(1.05);
}

.button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

@media (max-width: 700px) {

    .button { 
        width: 100%;
    }
    
}