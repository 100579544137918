.holder {
    border-radius: 4px;
    padding: 3em;
    border: solid 2px var(--pale-grey);
    background-color: var(--pale-grey);
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    color: var(--black60);
}

.holder:hover, .holder.dragging {
    border: solid 2px var(--main-decorator-color);
}

.holder.disabled {
    pointer-events: none;
}

.holder input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer !important;
    opacity: 0;
}

.holder .content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    pointer-events: none;
    background-color: var(--pale-grey-80);
}

.holder.disabled .content {
    opacity: 0.5;
}

.holder svg {
    width: 30px;
    height: 30px;
    margin-bottom: 10px;
}

.holder label {
    text-align: center;
    display: flex;
    justify-content: center;
    word-break: break-word;
    margin: 0;
    color: var(--black60);
}