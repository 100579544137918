.headerContainer {
    width: 100%;
}
.holder {
    width: 100%;
    height: var(--header-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    box-sizing: border-box;
    background-color: white;
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
}

.mobileLogo {
    display: none;
}

.mobileLogo img {
    height: 46px;
}

.right {
    display: flex;
    align-items: center;
}

.profileButton {
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    vertical-align: middle;
    transition: all 0.3s;
}

.profileButton:hover {
    transform: scale(1.02);
}

.profileButton label {
    margin-left: 10px;
    margin-bottom: 0;
    pointer-events: none;
}

.profileButton svg {
    margin-left: 10px;
}

.popoverContent button {
    width: 250px;
    height: 50px;
    padding: 0 20px;
    display: block;
    border: none;
    background: none;
    text-align: left;
    cursor: pointer;
    font-family: 'Montserrat';
    font-size: 14px;
    color: var(--black);
    border-bottom: 1px solid var(--very-light-pink);
    transition: all 0.3s;
}

.popoverContent button:hover {
    padding-left: 25px;
}

.popoverContent button:last-of-type {
    border-bottom: none;
}

.noAccountDisclaimer {
    margin: 0;
    font-size: 14px;
    cursor: pointer;
}
@media (max-width: 700px) {

    .desktopLogo {
        display: none;
    }

    .mobileLogo {
        display: block;
    }

    .profileButton label {
        display: none;
    }

}