.effChangeContainer {
    border: thin solid var(--main-decorator-color);
    border-radius:4px;
    padding: 2rem;
    background-color: var(--pale-grey);
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}
.bankModificationContainer > div > h4.added {
    color: #4caf50;
    caret-color: #4caf50;
}

.bankModificationContainer > div > h4.updated {
    color: var(--warning-color);
    caret-color: var(--warning-color);;
}

.bankModificationContainer > div > h4.deleted {
    color: #ff5252;
    caret-color: #ff5252;
}

.bankModificationContainer > * hr {
    width: 100%;
    border-top: none;
    border-color: var(--main-decorator-color30);

}

.evenColumns {
    display: flex;
    align-items: center;
    overflow: auto;
}

.evenColumns > * {
    flex: 1 1 10%;
}
.header > * {
    margin: 0;
}

.grid {
    display: flex;
    flex-wrap: wrap;
}
.grid > * {
    flex: 1 1 5em;
    align-self: center;
    justify-content: center;
}

.bankModificationContainer > * svg {
    margin: auto;
}

.col {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.col2 {
    text-transform: capitalize;
    flex: 0.166666666
}
.col4 {
    text-transform: capitalize;
    flex: 0.3333
}
.col6 {
    text-transform: capitalize;
    flex: 0.5
}

.col8 {
    flex: 0.66666;
}

.newValue {
    color: #4caf50;
    caret-color: #4caf50;
}

.oldValue {
    color: #ff5252;
    caret-color: #ff5252;
}

.effChangeHeader {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.effChangeHeader > * {
    flex: 1 1 50%
}
.effChangeHeader > h3 {
    flex: 0 0 30ch;
}
.effChangeHeader > div> button {
    flex: 0;
    height: 100%;
}

.linkField > div:first-child {
    background-color: var(--main-decorator-color30);
}
.linkField div div > input {
    background: white !important;
}

.linkField {
    flex: 1 1 100%;
    padding-right: 2rem;
}


