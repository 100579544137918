.page {
    box-sizing: border-box;
    position: relative;
    min-height: 100%;
    display: flex;
}

.light {
    color: var(--black40);
}

.noAccountDisclaimer {
    margin: 0;
    font-size: 14px;
}
.container {
    width: 100%;
    max-width: 70em;
    margin: auto;
    padding: 1.5rem;
    box-sizing: border-box;
    overflow-x: hidden;
}

.page button {
    margin-top: 1rem;
}

.footer {
    width: 100%;
    position: absolute;
    bottom: 20px;
    display: none;
}

@media (max-width: 700px) {

    .page {
        padding-top: var(--pre-auth-header-height);
        height: 100vh;
    }

    .noAccountDisclaimer {
        display: none;
    }



    .footer {
        display: flex;
    }

    .footer .noAccountDisclaimer {
        display: block;
        width: 100%;
        text-align: center;
        padding: 0 20px;
    }

}