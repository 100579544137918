.holder .field {
    background-color: var(--pale-grey);
    border: 2px solid transparent;
    width: 100%;
    height: 3.5em;
    box-sizing: border-box;
    border-radius: 4px;
    display: inherit;
    flex-grow: inherit;
    flex-direction: inherit;

    transition: all 0.3s;
}

.holder .field {
    position: relative;
    display: inherit;
    flex-grow: inherit;
    flex-direction: inherit;

}

.holder .field input, .holder .field textarea {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background: none;
    border: none;
    padding: 0 20px;
    display: inherit;
    flex-grow: inherit;
    flex-direction: inherit;
    font-family: 'Montserrat';
    font-size: 16px;
    color: var(--black);
    position: absolute;
}

.holder .field textarea {
    padding: 12.5px 20px;
    resize: none;
}

.holder .field .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 50px;
    right: 0;
    cursor: pointer;
}

.holder:hover .field {
    border: 2px solid var(--black20);
}

.holder.hasError .field {
    border: 2px solid var(--tomato);
}

.holder .field input:focus, .holder .field textarea:focus {
    outline: none !important;
}

.holder .field.focused {
    border: 2px solid var(--main-decorator-color);
}

.holder .field.disabled {
    background-color: var(--black20);
}

.holder .field input:disabled, .holder .field textarea:disabled {
    cursor: not-allowed;
}

.holder label {
    display: block;
    color: var(--tomato);
    margin: 0;
    margin-top: 2px;
}