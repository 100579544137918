.animatedContainer {
    display: inherit;
    flex-direction: inherit;
    flex-grow: inherit;
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }
    50% {
        opacity: 1
    }
}


@keyframes zoomOut {
    0% {
        opacity: 1
    }
    50% {
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3)
    }
    50%, to {
        opacity: 0
    }
}
