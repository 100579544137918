.popover {
    position: absolute;
    z-index: 9999;
    opacity: 0;
    pointer-events: none;
    width: max-content;
    transition: all 0.3s;
}

.popover.shown {
    opacity: 1;
    max-width: 600px;
    width: max-content;
    pointer-events: all;
}

.arrow {
    box-shadow: var(--drop-shadow);
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    background: white;
    transform: rotate(45deg);
    z-index: 1;
    border-radius: 1px;
}

.content {
    position: relative;
    margin-top: 16px;
    max-width: 450px;
    box-shadow: var(--drop-shadow);
    border-radius: 4px;
}

.content .inner {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    background-color: white;
    min-height: 330px;
    max-width: 350px;
    min-width: 360px;
    border-radius: 4px;
    padding: 2em;
    z-index: 2;
}

@media (max-width: 700px) {
    .content {
        max-width: 350px;
    }
}