/* Fonts */

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

/* Style variables */
:root {

  /* Colors */
  --black: #1e1d1c;
  --black80: rgb(30, 28, 28);
  --black60: rgba(30, 29, 28, 0.6);
  --black40: rgba(30, 29, 28, 0.4);
  --black20: rgba(30, 29, 28, 0.2);
  --main-decorator-color: #00874e;
  --main-decorator-color30: rgba(0, 135, 78, 0.3);
  --main-decorator-color40: rgba(0, 135, 78, 0.4);
  --warning-color: #fb8c00;
  --tomato: #f21f1f;
  --blue-grey: #94979c;
  --pale-grey: #f2f3f6;
  --pale-grey-80: rgba(242, 243, 246, 0.8);
  --very-light-pink: #dfdfdf;

  /* Shadow */
  --drop-shadow: 0 4px 16px 0 var(--black60);

  /* Dimensions */
  --header-height: 80px;
  --pre-auth-header-height: 114px
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #666;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html, body, #root {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: auto;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.67;
  color: var(--black);
}


@media (max-width: 700px) {

  body {
    font-size: 0.8rem;
    line-height: 1.56;
  }

}

/* Headers, texts and labels */

h1 {
  font-family: 'Montserrat';
  font-size: 2.625rem;
  font-weight: bold;
  line-height: 1.31;
  color: var(--black);
  margin: 1.5rem 0;
}

h2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.46;
  color: var(--black60);

  margin: 0;
  margin-bottom: 2.125rem;
}

h3 {
  font-family: 'Montserrat';
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.1;
  color: var(--black);

  margin: 0;
  margin-bottom: 10px;
}

h4 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.67;
  color: var(--black60);

  margin: 0;
  margin-bottom: 0;
}


p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.67;
  color: var(--black);

  margin-top: 0;
  margin-bottom: 20px;
}

label {
  display: inline-block;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  color: var(--black);

  margin-bottom: 10px;
}

a {
  color: var(--main-decorator-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

@media (max-width: 700px) {

  h1 {
    font-size: 1.75rem;
    line-height: 1.36;
  }

  h2 {
    font-size: 1.125rem;
    line-height: 1.44;
  }

  h3 {
    font-size: 1.125rem;
    line-height: 1.21;
  }

  h4 {
    font-size: 0.875rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.56;
  }

}

/* Loading */

.loader {
  -webkit-animation: rotating 4s linear infinite;
  -moz-animation: rotating 4s linear infinite;
  -ms-animation: rotating 4s linear infinite;
  -o-animation: rotating 4s linear infinite;
  animation: rotating 4s linear infinite;
}

.spinner-loading {
  display: inline-block;
  width: 25px !important;
  height: 25px !important;
  border: 3px solid var(--main-decorator-color30);
  border-radius: 50%;
  border-top-color: var(--main-decorator-color);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}