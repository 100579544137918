.homeContainer {
    padding: 7em 3em 2em;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.homeContainer > div:first-child {
    background-color: var(--pale-grey);
    border: solid 1px white;
    border-radius: 10px;
    padding: 0 20px;
    margin-bottom: 1rem;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

.textArea {
    height: 80px !important;
}

.enterActive {
    transition: zoomIn;
}
