.reportContainer > div > h3 {
    text-decoration: underline;
    text-decoration-color: var(--main-decorator-color);
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.errorContainer {
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    border: solid 2px var(--warning-color);
    border-radius: 5px;
    color: var(--warning-color);
}
.errorContainer span {
    align-items: center;
    display: flex;
    max-width: 50ch;
}
