.holder {
    position: relative;
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    background-color: white;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("../../assets/images/placeholder.svg");
}

.holder span {
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    position: absolute;
    right: -10px;
    bottom: 1px;
}
.holder span.accepted {
    background-color: var(--main-decorator-color);;
}
.holder span.rejected {
    background-color: var(--tomato);
}
.holder span svg {
    position: absolute;
    height: 18px;
    width: 18px;
}
.holder span svg {
    color: white;

}



